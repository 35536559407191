import React, { useContext } from "react";
import styles from "./index.module.scss";
import comingSoonImage from "../../assets/comingSoonImage.png";
import { AppContext } from "../../context";
import playStoreIcon from "../../assets/playStoreIcon.svg";
import appStoreIcon from "../../assets/appStoreIcon.svg";

import cx from "classnames";
const MobileAppComingSoon = () => {
  const { isMobile } = useContext(AppContext);
  return (
    <div
      className={cx(styles.comingSoonMainContainer, {
        [styles.comingSoonMainContainerMob]: isMobile,
      })}
    >
      {isMobile && (
        <div className={styles.comingSoonTextContainer}>
          <div className={styles.comingSoonTextMob}>Coming Soon On</div>
          <div className={styles.comingSoonIconsContainer}>
            <img
              src={appStoreIcon}
              alt="app store"
              className={styles.appStoreIconMob}
            />
            <img
              src={playStoreIcon}
              alt="play store"
              className={styles.playStoreIconMob}
            />
          </div>
        </div>
      )}
      <div className={styles.iconContainer}>
        <img
          src={comingSoonImage}
          alt="coming soon"
          className={cx(styles.comingSoonIcon, {
            [styles.comingSoonIconMob]: isMobile,
          })}
        />
      </div>
      {!isMobile && (
        <div className={styles.comingSoonTextContainer}>
          <div className={styles.comingSoonText}>Coming Soon On</div>
          <div className={styles.comingSoonIconsContainer}>
            <img
              src={appStoreIcon}
              alt="app store"
              className={styles.appStoreIcon}
            />
            <img
              src={playStoreIcon}
              alt="play store"
              className={styles.playStoreIcon}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileAppComingSoon;
